<template>
    <li class="flex items-center border-t border-gray-200 py-4">
      <span class="text-gray-600">{{ movimiento.FecMov }}</span>
      <div :class="movementColorClass" class="flex items-center ml-4">
        
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="blue" v-if="!isLast">
          <path fill-rule="evenodd" d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM2 10a8 8 0 0 1 8-8v16a8 8 0 0 1-8-8z" />
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500" viewBox="0 0 20 20" fill="green" v-if="isLast">
          <path fill-rule="evenodd" d="M7.293 10.293a1 1 0 0 0 1.414 1.414l3-3a1 1 0 1 0-1.414-1.414L8 10.586l-1.293-1.293a1 1 0 1 0-1.414 1.414l3 3z" clip-rule="evenodd" />
        </svg>
        
        <span class="ml-2" :class="textColor">{{ movimiento.DesMov }}</span>
      </div>
    </li>
  </template>
  
  <script>
  export default {
    props: {
        idx: {
            type: Number,
            required: true
        },
        movimiento: {
            type: Object,
            required: true
        },
        isLast: {
          type: Boolean,
          required: false,
          default: false
        },
    },
    computed: {
        movementColorClass() {
            return this.idx % 2 === 0 ? 'text-blue-500' : 'text-green-500';
        },
        textColor() {
            if (this.isLast) {
                return 'text-green-500';
            } else {
                return 'text-blue-500';
            }
        },
    }
  };
  </script>
