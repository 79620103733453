
<script setup>
import axios from 'axios';

import { ArrowRightIcon } from '@heroicons/vue/24/solid';
</script>

<template>
  <div class="min-h-screen flex flex-col items-center justify-center">
    <!-- Header -->
    <header class="bg-gray-800 text-white py-4 w-full">
      <div class="container mx-auto flex items-center justify-between">
        <!-- Logo -->
        <div class="flex items-center">
          <img src="@/assets/logo.png" alt="Logo" class="h-8 mr-2">
          <h1 class="text-lg font-semibold">Healy Colombia | Seguir orden</h1>
        </div>
      </div>
    </header>
    
    <!-- Main Content -->
    <main class="flex-1 w-full flex flex-col justify-center items-center">

      <!-- Logo -->
      <img src="@/assets/logo.png" alt="Logo" class="h-10 mt-8">
      <!-- Grey font -->
      <h2 class="text-2xl font-semibold mt-8 mb-8 text-gray-500">Rastrea tu orden</h2>

      <!-- Search Input -->
      <div class="relative w-3/4">
        <input
          type="text"
          placeholder="Introduce tu número de guía"
          class="w-full py-2 px-4 rounded-full border border-gray-400 focus:outline-none focus:border-blue-500 hover:border-blue-500 transition-colors"
          v-model="trackingNumber"
          @keyup.enter="search"
        >
        <button
          class="absolute right-0 top-0 mt-1 mr-2"
          @click="search"
        >

        <span class="hover-icon">
          <ArrowRightIcon class="h-8 w-6 text-gray-600" />
        </span>
        </button>

      </div>
      
      <div v-if="loading" class="loader"></div>

      <!-- Error div -->
      <div v-if="error" class="mt-4 p-4 bg-red-100 text-red-600 rounded-lg w-3/4">
        <p class="text-center">No se encontraron resultados para el número de guía ingresado.</p>
      </div>

      <!-- Container with order details -->
      <Transition>
        <div v-if="orderDetails" class="mt-8 p-4 bg-gray-100 rounded-lg w-3/4">
          <h2 class="text-lg font-semibold mb-4">Detalles de la orden</h2>
          <div class="grid grid-cols-2 gap-x-4">
            <div class="flex flex-col">
              <p class="mt-1"><span class="font-semibold">Fecha Probable:</span> {{ orderDetails.FecProbable }}</p>
              <p class="mt-1"><span class="font-semibold">Número de guía:</span> {{ orderDetails.NumGui }}</p>
              <p class="mt-1"><span class="font-semibold">Fecha de envío:</span> {{ orderDetails.FecEnv }}</p>
              <p class="mt-1"><span class="font-semibold">Número de piezas:</span> {{ orderDetails.NumPie }}</p>
              <p class="mt-1"><span class="font-semibold">Ciudad Remitente:</span> {{ orderDetails.CiuRem }}</p>
              <p class="mt-1"><span class="font-semibold">Nombre Remitente:</span> {{ orderDetails.NomRem }}</p>
            </div>
            <div class="flex flex-col">
              <p class="mt-1"><span class="font-semibold">Ciudad Destinatario:</span> {{ orderDetails.CiuDes }}</p>
              <p class="mt-1"><span class="font-semibold">Nombre Destinatario:</span> {{ orderDetails.NomDes }}</p>
              <p class="mt-1"><span class="font-semibold">Dirección Destinatario:</span> {{ orderDetails.DirDes }}</p>
              <p class="mt-1"><span class="font-semibold">Estado Actual:</span> {{ orderDetails.EstAct }}</p>
              <p class="mt-1"><span class="font-semibold">Fecha de Estado:</span> {{ orderDetails.FecEst }}</p>
              <p class="mt-1"><span class="font-semibold">Régimen:</span> {{ orderDetails.Regime }}</p>
            </div>
          </div>
        </div>
      </Transition>


      <Transition>
        <div v-if="orderDetails" class="mt-8 p-4 bg-gray-100 rounded-lg w-3/4">
          <h2 class="text-lg font-semibold">Movimientos de la orden</h2>
          <ul class="mt-2" v-if="orderDetails.Mov">
            <MovementListItem v-for="(m, index) in orderDetails.Mov" :key="index" :idx="index" :isLast="index === orderDetails.Mov.length - 1" :movimiento="{ FecMov: m.FecMov, DesMov: m.DesMov }" />
          </ul>
          <p v-else>No hay movimientos disponibles.</p>
        </div>
      </Transition>

      <Transition>
        <div v-if="orderDetails" class="mt-8 p-4 bg-gray-100 rounded-lg w-3/4">
          <h2 class="text-lg font-semibold">Mayor información</h2>
            <!-- go to https://www.servientrega.com/wps/portal/rastreo-envio for mor info-->
            <p class="mt-1">Para mayor información sobre el estado de tu paquete, puedes visitar la página de Servientrega e introducir tu guía: {{ orderDetails.NumGui }}</p>
            <a href="https://www.servientrega.com/wps/portal/rastreo-envio" target="_blank" class="text-blue-500 hover:underline">Ir a Servientrega</a>
          </div>
      </Transition>

    </main>
    
    <!-- Footer -->
    <footer class="bg-gray-200 py-4 w-full">
      <div class="container mx-auto text-gray-700 text-center">
        <h2 class="text-lg font-semibold">Healy Colombia</h2>
        <p class="mt-1">¿Dudas adicionales?</p>
        <p class="mt-1">Tel. +57 310 227 7538</p>
        <p class="mt-1"><a href="mailto:infocol@teramin.com.mx">
          infocol@teramin.com.mx</a></p>


      </div>
    </footer>
  </div>
</template>

<script>
import MovementListItem from './MovementListItem.vue'; 
export default {
  components: {
    MovementListItem,
  },
  data() {
    return {
      trackingNumber: '',
      orderDetails: null,
      loading: false,
      error: false,
    };
  },
  methods: {
    async search() {
      try {
        this.loading = true;
        this.orderDetails = null;
        this.error = false;
        const response = await axios.get(`https://zohocol.teramin.com.mx/api/setr/${this.trackingNumber}`);
        if (response.data.IdEstAct === "0") {
          this.error = true;
        } else {
          this.orderDetails = response.data;
        }
      } catch (error) {
        console.error(error);
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  },
  beforeMount: function() {
    const urlParams = new URLSearchParams(window.location.search);
    const trackingNumber = urlParams.get('t');
    if (trackingNumber) {
      this.trackingNumber = trackingNumber;
      this.search();
    }
  }
};
</script>

<style>
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.hover-icon:hover svg {
  fill: #8911d4;
  transition: fill 0.3s ease;
}

.hover-icon:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>