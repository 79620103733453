<template>
  <TrackerComponent />
</template>

<script>
import TrackerComponent from './components/TrackerComponent.vue'

export default {
  name: 'App',
  components: {
    TrackerComponent
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
